@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Barlow";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  margin: 0;
  height: 100%;
  background-color: #f4f4f4;
}

.ReactModalPortal {
  position: absolute;
  z-index: 100;
}

.ticket ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.ticket ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(200, 200, 200, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(200, 200, 200, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.Dropdown-menu {
  max-height: 150px;
  overflow-y: scroll;
}

.calendarAboveInput .rdtPicker,
input {
  color: #e2e2e2;
  border-color: #828282;
  background-color: #21262a;
  bottom: 45px;
  outline-width: 0ch;
  left: -20px;
}

.rdtPicker .rdtActive {
  background-color: #8454eb !important;
}

.rdtPicker .rdtNew:hover {
  background-color: #828282 !important;
  color: #c2c2c2;
}

.rdtPicker .rdtSwitch:hover {
  background-color: #2a3135 !important;
}

.rdtPicker .rdtPrev:hover {
  background-color: #2a3135 !important;
}

.rdtPicker .rdtNext:hover {
  background-color: #2a3135 !important;
}

.rdtPicker .rdtTimeToggle:hover {
  background-color: #2a3135 !important;
}

.rdtPicker .rdtBtn:hover {
  background-color: #2a3135 !important;
}

.rdtPicker .rdtDay:hover {
  background-color: #828282 !important;
}
.rdtPicker .rdtToday::before {
  border-bottom-color: #8454eb !important;
}

.calendarAboveInput,
input {
  color: #b2b2b2;
  border-color: #828282;
  background-color: #121619;
}

#root {
  min-height: 100%;
  font-family: "Barlow";
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.polygonpath {
  stroke-dasharray: 2500;
  stroke-dashoffset: 2500;
  animation: dash 2s linear alternate infinite;
}

@keyframes dash {
  from {
    stroke-dashoffset: 2000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
